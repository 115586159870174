import { TaurusQueryItem, TaurusQueryItemColumn } from '@cognitiv/galaxy-api';
import { taurus_partitioned_column_default } from 'products/taurus/operators/columns/defaults';
import { TAURUS_COMPRESSION_TYPE, TAURUS_COMPRESSION_TYPE_NAME } from 'products/taurus/operators/compression-formats-list/enums';
import { TAURUS_OUTPUT_TYPE, TAURUS_OUTPUT_TYPE_NAME } from 'products/taurus/operators/output-types-list/enums';
import { TAURUS_QUERY_ENGINE_NULL } from 'products/taurus/operators/query-engines-list/enums';
import { TAURUS_ROW_TYPE } from 'products/taurus/operators/row-types-list/enums';
import { TAURUS_SQL_TEMPLATE_ENGINE, TAURUS_SQL_TEMPLATE_ENGINE_NAME } from 'products/taurus/operators/sql-template-engines-list/enums';

export const taurus_query_item_default: TaurusQueryItem = {
  dimensions: [],
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  attributes: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  job_id: null,
  item_state_id: 0,
  item_state_name: '',
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  description: null,
  is_definition: false,
  parent_item_ids: [],
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  max_memory_usage_gb: null,
  max_memory_usage_gb_formatted: '',
  max_memory_usage_bytes: null,
  max_memory_usage_bytes_formatted: '',
  dataset_id: 0,
  sql_template_id: null,
  sql_engine_name: 'ClickHouse',
  sql_engine_id: 3,
  sql_template_name: null,
  query_engine_name: TAURUS_QUERY_ENGINE_NULL,
  row_type_id: TAURUS_ROW_TYPE.IMPRESSION,
  query_engine_id: null,
  parameterized_sql: '',
  parameters: [],
  inherited_parameters: [],
  cumulative_parameters: [],
  definition_item_id: null,
  sql_template_engine_id: TAURUS_SQL_TEMPLATE_ENGINE.COGNITIV,
  sql_template_engine_name: TAURUS_SQL_TEMPLATE_ENGINE_NAME[TAURUS_SQL_TEMPLATE_ENGINE.COGNITIV],
  s3_bucket: '',
  s3_prefix: '',
  glue_table_name: null,
  output_type_name: TAURUS_OUTPUT_TYPE_NAME[TAURUS_OUTPUT_TYPE.PARQUET],
  output_type_id: TAURUS_OUTPUT_TYPE.PARQUET,
  output_compression_format_name: TAURUS_COMPRESSION_TYPE_NAME[TAURUS_COMPRESSION_TYPE.SNAPPY],
  output_compression_format_id: TAURUS_COMPRESSION_TYPE.SNAPPY,
  row_count: 0,
  row_count_formatted: '0',
  is_persistent: false,
  columns: [],
  created_datetime_formatted: '',
  saved: false,
  uuid: '',
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_from: '',
  item_id_to: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  query_execution_timeout_seconds_formatted: '',
  partition_column_id: null,
  partition_column_name: '',
  partition_name: null,
  partition_count: null,
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  query_execution_timeout_seconds: null,
  compute_costs_estimate_usd_formatted: '',
  compute_costs_estimate_usd: null,
};

export const taurus_query_column_default: TaurusQueryItemColumn = { ...taurus_partitioned_column_default };

import React, { useEffect, useRef, useState } from 'react';
import { AutoSizer } from 'react-virtualized';

import cn from 'products/taurus/overlays/manage-taurus-workspaces/components/truncated-list/TruncatedList.module.scss';

interface TruncatedListProps<T> {
  items: T[];
  renderTruncator: () => React.JSX.Element;
  renderItem: (item: T, index: number) => React.JSX.Element;
}

export const TruncatedList = <T,>({ items, renderTruncator, renderItem }: TruncatedListProps<T>): React.JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [is_truncated, isTruncated] = useState<boolean>(false);

  useEffect(() => {
    const checkWidth = () => {
      if (containerRef.current) {
        if (is_truncated) return;
        const containerWidth = containerRef.current.offsetWidth;
        const childNodes = Array.from(containerRef.current.children) as HTMLElement[];
        let totalWidth = 0;

        childNodes.forEach((node) => {
          totalWidth += node.offsetWidth;
        });

        isTruncated(totalWidth > containerWidth);
      }
    };

    const resizeObserver = new ResizeObserver(checkWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Check width initially and on window resize
    checkWidth();
    window.addEventListener('resize', checkWidth);

    return () => {
      window.removeEventListener('resize', checkWidth);
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [items.length]); // Consider dependencies if items might change that affect rendering

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <div className={cn.list} style={{ height, width, maxWidth: width }} ref={containerRef}>
            {items.map((item, index) => {
              if (is_truncated && index > 0 && index < items.length - 1) {
                return index === 1 ? renderTruncator() : null;
              }
              return renderItem(item, index);
            })}
          </div>
        );
      }}
    </AutoSizer>
  );
};

import { EMPTY_ARRAY } from '@cognitiv/cassiopeia-ui';
import { TaurusDimension, TaurusDimensionPayload, TaurusParameter, TaurusParameterAPI, TaurusPartitionedBaseColumn } from '@cognitiv/galaxy-api';
import { TABLE_SETTINGS } from 'products/taurus/components/tables/constants';
import {
  PartitionedColumnsProps,
  PartitionedColumnsReturnType,
  TaurusDefaultTitleProps,
  validateItemColumnsProps,
} from 'products/taurus/modals/types';
import { TAURUS_COLUMN_TYPE } from 'products/taurus/operators/column-types-list/enums';

interface GetTableMinHeightOptions {
  row_height?: number;
  header_height?: number;
}

interface GetTableHeightOptions {
  rows?: number;
  max_height?: number;
  min_height?: number;
  row_height?: number;
  header_height?: number;
  include_scroll?: boolean;
}

export const createParameters = (parameters: TaurusParameter[]): TaurusParameterAPI => {
  return parameters.reduce((acc, next) => {
    if (next.parameter_name && next.parameter_value) {
      acc[next.parameter_name] = next.parameter_value;
    }
    return acc;
  }, {});
};

export const createDimensions = (dimensions: TaurusDimension[] = EMPTY_ARRAY): TaurusDimensionPayload[] =>
  dimensions
    .filter((dimension) => dimension.dimension_name)
    .map((dimension, i: number) => ({
      dimension_name: dimension.dimension_name || '',
      dimension_index: i,
    }));

export const validateItemColumns = <T extends { [key: string]: any }>({ columns }: validateItemColumnsProps<T>): string[] => {
  if (columns.length === 0) {
    return ['Please make sure to have at least one column'];
  }

  if (columns.length === 1 && !columns[0].column_type_id) {
    return ['Please make sure to have at least one column'];
  }

  return columns.reduce((acc: string[], next) => {
    if (!next.column_type_id) {
      return acc;
    }
    if (next.column_type_id === TAURUS_COLUMN_TYPE.FEATURE && !next.feature_identifier) {
      acc.push('Columns with type feature must have a feature name');
    }
    if (next.column_type_id === TAURUS_COLUMN_TYPE.LABEL && !next.column_name) {
      acc.push('Columns with type label must have a column name');
    }
    if (next.column_type_id === TAURUS_COLUMN_TYPE.LABEL && !next.label_type_id) {
      acc.push('Columns with type label must have a label type');
    }
    if (next.column_type_id === TAURUS_COLUMN_TYPE.OTHER && !next.column_name) {
      acc.push('Columns with type other must have a column name');
    }
    return acc;
  }, []);
};

export const validateItemColumnsPartition = <T extends TaurusPartitionedBaseColumn>({
  columns = EMPTY_ARRAY,
}: PartitionedColumnsProps<T>): PartitionedColumnsReturnType<T> => {
  const parsed_coumns = [...columns];
  const partition_exists = parsed_coumns.some((column) => column.partition_on);

  if (partition_exists) {
    return { columns: parsed_coumns, message: '', error: false };
  }

  const cuid_exists = parsed_coumns.find((column) => column.feature_identifier === 'f2505d2c-a50c-44b2-b022-1acf9d3e71ba');

  if (cuid_exists) {
    return {
      columns: parsed_coumns.reduce((acc: T[], next) => {
        if (next.feature_identifier === cuid_exists.feature_identifier) {
          acc.push({
            ...next,
            partition_name: 'partition',
            partition_count: 10,
            partition_count_formatted: '10',
            partition_on: true,
          });
          return acc;
        }
        acc.push(next);
        return acc;
      }, []),
      message: 'CUID will be set as the default partition with a partition count of 10',
      error: true,
    };
  }

  return {
    columns: parsed_coumns.reduce((acc: T[], next, index) => {
      if (index === 0) {
        acc.push({
          ...next,
          partition_name: 'partition',
          partition_count: 1,
          partition_count_formatted: '1',
          partition_on: true,
        });
        return acc;
      }
      acc.push(next);
      return acc;
    }, []),
    message: 'First column will be set as the default partition with a partition count of 1',
    error: true,
  };
};

export const getTableHeight = (options?: Partial<GetTableHeightOptions>) => {
  const {
    rows = 0,
    max_height = 400,
    min_height = 200,
    row_height = TABLE_SETTINGS.cell.height,
    header_height = TABLE_SETTINGS.header.height,
    include_scroll = false,
  } = options ?? {};

  const height_outline = row_height + 2;
  const height = rows * height_outline + header_height + 2 + (include_scroll ? 16 : 0);

  if (rows === 0) return min_height;

  return height > max_height ? max_height : height;
};

export const getTableMinHeight = (options?: Partial<GetTableMinHeightOptions>) => {
  const { row_height = TABLE_SETTINGS.cell.height + 2, header_height = TABLE_SETTINGS.header.height } = options ?? {};

  return row_height + header_height;
};

export function getDefaultTitle({ read_only, item_id, saved, title }: TaurusDefaultTitleProps) {
  if (read_only) {
    return `View ${title}${item_id ? ` - ${item_id}` : ''}`;
  }
  return saved ? `Update ${title}${item_id ? ` - ${item_id}` : ''}` : `Create ${title}`;
}

import { TaurusNonEtlQueryItem } from '@cognitiv/galaxy-api';
import { TAURUS_QUERY_ENGINE_NULL } from 'products/taurus/operators/query-engines-list/enums';
import { TAURUS_SQL_TEMPLATE_ENGINE, TAURUS_SQL_TEMPLATE_ENGINE_NAME } from 'products/taurus/operators/sql-template-engines-list/enums';

export const taurus_non_etl_query_item_default: TaurusNonEtlQueryItem = {
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  attributes: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  job_id: null,
  item_state_id: 0,
  item_state_name: '',
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  description: null,
  is_definition: false,
  parent_item_ids: [],
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  sql_template_id: null,
  sql_template_name: null,
  sql_engine_id: 3,
  sql_engine_name: 'ClickHouse',
  query_engine_id: null,
  query_engine_name: TAURUS_QUERY_ENGINE_NULL,
  parameterized_sql: '',
  max_memory_usage_gb: null,
  max_memory_usage_gb_formatted: '',
  max_memory_usage_bytes: null,
  max_memory_usage_bytes_formatted: '',
  parameters: [],
  inherited_parameters: [],
  cumulative_parameters: [],
  sql_template_engine_id: TAURUS_SQL_TEMPLATE_ENGINE.COGNITIV,
  sql_template_engine_name: TAURUS_SQL_TEMPLATE_ENGINE_NAME[TAURUS_SQL_TEMPLATE_ENGINE.COGNITIV],
  created_datetime_formatted: '',
  definition_item_id: null,
  saved: false,
  uuid: '',
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_from: '',
  item_id_to: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  query_execution_timeout_seconds_formatted: '',
  query_execution_timeout_seconds: null,
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  compute_costs_estimate_usd_formatted: '',
  compute_costs_estimate_usd: null,
};

import React, { useRef } from 'react';
import { Action, Button, CaptureFixedClick, Icon, InputText, Splitter, SplitterPanel } from '@cognitiv/cassiopeia-ui';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { SmallSpinner } from 'components/layout/loading/Loading';
import { useMenuContext } from 'components/menu/context/MenuContext';
import { selectModal } from 'ducks/modals/selectors';
import { useTheme } from 'hooks/useTheme';
import { Parameters } from 'products/taurus/components/parameters/Parameters';
import { TAURUS_MODALS } from 'products/taurus/modals/types';
import { WorkspaceFolder } from 'products/taurus/overlays/manage-taurus-workspaces/components/workspace-folder/WorkspaceFolder';
import { WorkspacePath } from 'products/taurus/overlays/manage-taurus-workspaces/components/workspace-path/WorkspacePath';
import {
  TaurusWorkspacesContext,
  useManageWorkspacesContext,
} from 'products/taurus/overlays/manage-taurus-workspaces/context/TaurusWorkspacesContext';
import { AutoSizer } from 'react-virtualized';
import { useAppSelector } from 'store/hooks';

import cn from 'products/taurus/overlays/manage-taurus-workspaces/ManageTaurusWorkspaces.module.scss';

const ManageWorkspaces = () => {
  const { is_open } = useAppSelector((state) => selectModal(state, TAURUS_MODALS.MANAGE_TAURUS_WORKSPACES));
  const manage_taurus_workspace = useAppSelector((state) => selectModal(state, TAURUS_MODALS.MANAGE_TAURUS_WORKSPACE));
  const divRef = useRef(null);

  const {
    theme: { action },
  } = useTheme();

  const { is_menu_open } = useMenuContext();

  const {
    workspace,
    current_workspace_index,
    current_workspace_children,
    current_workspace,
    is_loading_children,
    is_loading_workspace,
    onClose,
    onSubmit,
    onWorkspacePrev,
    onWorkspaceNext,
    onSelectWorkspace,
    editWorkspace,
  } = useManageWorkspacesContext();

  if (!is_open) {
    return null;
  }

  return (
    <CaptureFixedClick
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refs={[divRef]}
      onClickOutside={(e) => {
        const button = document.getElementById('button-cassiopeia');

        if (manage_taurus_workspace.is_open) return;

        if (button) {
          const is_inside = button.contains(e.target as Node);
          if (is_inside) return;
        }

        onClose();
      }}
    >
      <div className={classNames(cn.wrapper, { [cn.slim]: is_menu_open })} ref={divRef}>
        <Splitter mode="horizontal" variant="quaternary">
          <SplitterPanel size="65%" min_size={372}>
            <div className={classNames(cn.section, cn.left)}>
              {is_loading_children && (
                <div className={cn.blur}>
                  <SmallSpinner />
                </div>
              )}
              <div className={cn.header}>
                <Button variant="tertiary" left_icon={faChevronLeft} min_width="32px" onClick={onWorkspacePrev} />
                <Button variant="tertiary" left_icon={faChevronRight} min_width="32px" onClick={onWorkspaceNext} />
                <Action
                  onClick={() => onSelectWorkspace(Number(current_workspace.workspace_id_path_split[current_workspace_index]))}
                  theme={action.label}
                >
                  {current_workspace.workspace_path_split[current_workspace_index]}
                </Action>
                <div className={cn.grow} />
                <Action theme={action.label}>
                  <Icon icon={faClose} onClick={onClose} theme={{ icon: { font_size: '15px' } }} />
                </Action>
              </div>
              <div className={cn.break} />
              <div className={cn.content}>
                <AutoSizer>
                  {({ height, width }) => (
                    <div className={cn.folders} style={{ height, width }}>
                      <WorkspaceFolder workspace_name="Add Workspace" />
                      {current_workspace_children.map((c) => (
                        <WorkspaceFolder key={c.workspace_id} workspace_id={c.workspace_id} workspace_name={c.workspace_name} />
                      ))}
                    </div>
                  )}
                </AutoSizer>
              </div>
              <div className={cn.footer}>
                <WorkspacePath />
                <Button onClick={onClose} variant="subtle">
                  Cancel
                </Button>
                <Button onClick={onSubmit}>Enter Workspace</Button>
              </div>
            </div>
          </SplitterPanel>
          <SplitterPanel size="35%" min_size={406}>
            <div className={classNames(cn.section, cn.right)}>
              {is_loading_workspace && (
                <div className={cn.blur}>
                  <SmallSpinner />
                </div>
              )}
              <div className={cn.header}>
                <p className={cn.title}>Workspace: {workspace.workspace_name}</p>
              </div>
              <div className={cn.break} />
              {!is_loading_workspace && (
                <div className={cn.body}>
                  <InputText label="Name" key={workspace.workspace_id} value={workspace.workspace_name} disabled />
                  <Parameters
                    label="Inherited Parameters"
                    parameters={workspace.inherited_parameters}
                    parameters_override={workspace.parameters}
                    read_only
                  />
                  <Parameters label="Parameters" parameters={workspace.parameters} read_only />
                </div>
              )}
              <div className={cn.footer}>
                <div className={cn.grow} />
                <Button onClick={() => editWorkspace(workspace)}>Edit</Button>
              </div>
            </div>
          </SplitterPanel>
        </Splitter>
      </div>
    </CaptureFixedClick>
  );
};

export const ManageTaurusWorkspaces = () => (
  <TaurusWorkspacesContext>
    <ManageWorkspaces />
  </TaurusWorkspacesContext>
);

import { TaurusSqlTemplate, TaurusSqlTemplateColumn } from '@cognitiv/galaxy-api';
import { uuidv4 } from 'utils/uuid';

export const taurus_sql_template_default: TaurusSqlTemplate = {
  columns: [],
  sql_template_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  sql_template_name: '',
  sql_engine_name: 'ClickHouse',
  sql_engine_id: 3,
  parameterized_sql: '',
  is_archived: false,
  created_by: '',
  created_date: '',
  created_datetime_formatted: '',
  row_type_id: 0,
  saved: false,
  uuid: '',
};

export const taurus_sql_template_column_default: TaurusSqlTemplateColumn = {
  sql_template_id: 0,
  column_name: '',
  column_index: 0,
  column_index_formatted: '',
  column_type_name: '',
  column_type_id: 0,
  unique_feature_name: '',
  feature_identifier: null,
  label_type_name: '',
  label_type_id: null,
  label_group: '',
  data_type_name: '',
  data_type_id: null,
  extended_data: '',
  feature_name: '',
  array_length: null,
  array_length_formatted: '',
  is_array: false,
  saved: false,
  uuid: uuidv4(),
};
